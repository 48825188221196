import React from "react";
import { useSelector } from 'react-redux';
import { getImageUrl } from '../utils';
import { useEffect } from "react";
import separator from '../images/sep.png'


const Team = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const cmsData = useSelector((state) => state.cms.cmsData);
	const teams = useSelector((state) => state.team.team || []);

	console.log(cmsData);
	console.log(teams);
	return (
		<>
			<section className="inner-banner py-5 position-relative wow animated fadeInDown">
				<em className="bg-black opacity-75 position-absolute top-0 end-0 start-0 bottom-0"></em>
				<div className="container py-md-5 py-4">
					<div className="row">
						<div className="col-12 text-center">
							<nav aria-label="breadcrumb" className="position-relative z-1 d-flex justify-content-center">
								<ol className="breadcrumb fs-5 mb-0 py-5">
									<li className="breadcrumb-item"><a href="index.html" className="text-white">Home</a></li>
									<li className="breadcrumb-item fw-bolder text-primary-dt" aria-current="page">Our Team</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</section>

			<section className="ceo py-5 overflow-hidden wow animated fadeInDown">
				<div className="container py-md-5 py-4">
					<div className="row align-items-center">
						<div className="col-lg-6 my-4 order-lg-1 order-2">
							<div className="img-box overflow-hidden rounded-5 wow animated slideInLeft"><img src={getImageUrl(cmsData.ceo_main_image)} alt="CEO" className="img-fluid hvr-bob rounded-5 shadow border" /></div>
						</div>
						<div className="col-lg-6 my-4 ps-lg-5 order-lg-2 order-1">
							<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated slideInRight">Founder / CEO</span>
							<h2 className="h1 ff-josefine fw-bold wow animated slideInRight">{cmsData.ceo_title}</h2>
							<img src={separator} alt="Seperator" className="wow animated slideInRight dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated slideInRight light-sep" />
							<p className="d-block wow animated slideInRight lh-170 pt-4 mt-2 fs-5" dangerouslySetInnerHTML={{
								__html: cmsData.ceo_description,
							}}></p>
							<ul className="d-flex gap-4 wow animated slideInRight mt-4 pt-2">
								<li><a href={cmsData.ceo_facebook_link} className="text-primary-dt"><i className="fab fa-facebook fs-4"></i></a></li>
								<li><a href={cmsData.ceo_instagram_link} className="text-primary-dt"><i className="fab fa-instagram fs-4"></i></a></li>
								<li><a href={cmsData.ceo_linkedin_link} className="text-primary-dt"><i className="fab fa-linkedin-in fs-4"></i></a></li>
								<li><a href={cmsData.ceo_twitter_link} className="text-primary-dt"><i className="fab fa-twitter fs-4"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section className="team py-5 wow animated fadeInDown bg-light">
				<div className="container py-md-5 py-4">
					<div className="row">
						<div className="col-12 text-center">
							<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated fadeInDown">{cmsData.ceo_title}</span>
							<h2 className="h1 ff-josefine fw-bold wow animated fadeInDown">{cmsData.ceo_board_of_director}</h2>
							<img src={separator} alt="Seperator" className="wow animated slideInRight dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated slideInRight light-sep" />
						</div>
					</div>
					<div className="row align-items-stretch mt-5">
						{Array.isArray(teams) && teams.length > 0 ? (
							teams.map((team) => (
								<div key={team.id} className="col-lg-3 col-md-6 mt-4">
									<div className="position-relative bg-white rounded-2 overflow-hidden border shadow-sm wow animated fadeInDown team-box transition hvr-float">
										<img src={getImageUrl(team.image)} alt="Team" className="img-fluid border-bottom team-image" />
										<div className="p-4">
											<h3 className="h4 ff-josefine">{team.name}</h3>
											<strong className="text-primary-dt fs-13">{team.designation}</strong>
										</div>
									</div>
								</div>
							))
						) : (
							<p>No team members available</p>
						)}
					</div>
				</div>
			</section>
		</>
	);
}
export default Team;