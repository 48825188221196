import axios from "axios";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Form() {

    const intializeData = {
        first_name : '',
        last_name : '',
        subject : '',
        email : '',
        message : '',
    }

    const [formData, setFormData] = useState(intializeData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const recaptchaRef = useRef(null);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]:value});
    }
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/contact-us-store`, {
                ...formData,
                recaptchaToken
            });
            toast.success("Form submitted successfully!");
            setFormData(intializeData);
            setRecaptchaToken(null);
            setErrors({});
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.error); // Set errors from API response
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                }
                setRecaptchaToken(null);
            }
        } finally {
            setLoading(false);
        }
    }
    return <div>
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <div className="form-group">
                        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} className="form-control rounded-2 p-3 mb-2" placeholder="First Name" />
                        {errors.first_name && <small className="text-danger">{errors.first_name[0]}</small>}
                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="form-group">
                        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} className="form-control rounded-2 p-3 mb-2" placeholder="Last Name" />
                        {errors.last_name && <span className="text-danger">{errors.last_name[0]}</span>}

                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="form-group">
                        <input type="text" name="subject" value={formData.subject} onChange={handleChange} className="form-control rounded-2 p-3 mb-2" placeholder="Subject" />
                        {errors.subject && <span className="text-danger">{errors.subject[0]}</span>}

                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="form-group">
                        <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control rounded-2 p-3 mb-2" placeholder="Email Address" />
                        {errors.email && <span className="text-danger">{errors.email[0]}</span>}

                    </div>
                </div>
                <div className="col-lg-12 mb-4">
                    <div className="form-group">
                    <textarea name="message" value={formData.message} onChange={handleChange}
                        className="form-control rounded-2 p-3 mb-2" rows="4" placeholder="Enter Your Message"
                    ></textarea>                    
                    {errors.message && <span className="text-danger">{errors.message[0]}</span>}

                    </div>
                </div>
                <div className="col-12 mb-4">
                    <ReCAPTCHA
                        ref={recaptchaRef} // Attach ref to the reCAPTCHA component
                        sitekey="6Lfwd4EpAAAAAFES60Vktlatqti95O6X9bLTwR1N" // Replace with your actual reCAPTCHA site key
                        onChange={handleRecaptchaChange}
                    />
                    {errors.recaptcha && <small className="text-danger">{errors.recaptcha[0]}</small>}
                </div>
                <div className="col-lg-12 mb-lg-0 mb-md-0 mb-4">
                    <div className="form-group">
                    <button 
                        type="submit" 
                        className="btn btn-primary-dt rounded-2 btn-lg w-100 fw-bold py-3" 
                        disabled={loading || !recaptchaToken} // Disable until reCAPTCHA is completed
                    >
                        {loading ? "Submitting..." : "SUBMIT"}
                    </button>                    
                    </div>
                </div>
            </div>
        </form>
    </div>;
}

export default Form;
