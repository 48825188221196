import React from "react";
import { useSelector } from 'react-redux';
import logo from "../../images/logo-devio-tech-white.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer=()=>{
	const cmsData = useSelector((state) => state.cms.cmsData);

	return(
		<>
		<footer className="bg-dark pt-5 text-white wow animated fadeInDown">
			<div className="container py-4 pb-4">
				<div className="row justify-content-between">
				<div className="col-md-6 col-xxl-3 col-xl-3">
					<div className="footer-widget mt-4">
					<a href="index.html#home"><img src={logo} alt="logo" width="240"/></a>
					<p className="d-block lh-180 py-4" dangerouslySetInnerHTML={{
						 __html: cmsData.footer_description,
					}}></p>
					<ul className="d-flex gap-4 mt-4">
						<li><a href={cmsData.footer_facebook_link} target="_blank"><i className="fab fa-facebook-f fs-5"></i></a></li>
						<li><a href={cmsData.footer_twitter_link} target="_blank"><i className="fab fa-twitter fs-5"></i></a></li>
						<li><a href={cmsData.footer_linkdin_link} target="_blank"><i className="fab fa-linkedin-in fs-5"></i></a></li>
						<li><a href={cmsData.footer_intagram_link} target="_blank"><i className="fab fa-instagram fs-5"></i></a></li>
					</ul>
					</div>
				</div>
				<div className="col-md-6 col-xl-auto">
					<div className="footer-widget mt-4">
					<h3 className="fs-4 ff-josefine mb-4">Useful Links</h3>
					<ul className="menu">
						<li className="d-block py-3"><Link to="/">Home</Link></li>
						<li className="d-block py-3"><HashLink to="/#aboutUs">About Us</HashLink></li>
						<li className="d-block py-3"><HashLink to="/#ourServices">Our Services</HashLink></li>
						<li className="d-block py-3"><HashLink to="/#clients">Clients</HashLink></li>
						<li className="d-block py-3"><HashLink to="/#projects">Projects</HashLink></li>
					</ul>
					</div>
				</div>
				<div className="col-md-6 col-xl-auto">
					<div className="footer-widget mt-4">
					<h3 className="fs-4 ff-josefine mb-4">Common Links</h3>
					<ul className="menu">
						<li className="d-block py-3"><HashLink to="/#achievements">Acheivements</HashLink></li>
						<li className="d-block py-3"><Link to="/">Get In Touch</Link></li>
						<li className="d-block py-3"><HashLink to="/team">Team</HashLink></li>
						<li className="d-block py-3"><Link to="/">Our Blog</Link></li>
						<li className="d-block py-3"><Link to="/">Terms &amp; Conditions</Link></li>
					</ul>
					</div>
				</div>
				<div className="col-md-6 col-xl-3">
					<div className="footer-widget mt-4">
					<h3 className="fs-4 ff-josefine mb-4">Reach Us</h3>
					<ul className="d-block mt-4 ps-0">
						<li className="d-flex gap-3 my-4 py-1"><i className="fa fa-phone-alt mt-1 text-primary-dt"></i> <a href={`tel:${cmsData.contact_us_number}`} className="text-white transition lh-150">{cmsData.contact_us_number}</a></li>
						<li className="d-flex gap-3 my-4 py-1"><i className="fa fa-envelope mt-1 text-primary-dt"></i> <a href={`mailto:${cmsData.contact_us_email}`} className="text-white transition lh-150">{cmsData.contact_us_email}</a></li>
						<li className="d-flex gap-3 my-4 py-1"><i className="fa fa-home mt-1 text-primary-dt"></i> <a href="https://maps.app.goo.gl/dszUCjjF9oNpkkKf8" target="_blank" className="text-white transition lh-150">{cmsData.iframe_link}</a></li>
					</ul>
					</div>
				</div>
				</div>
			</div>
			<div className="copyright bg-black p-4 mt-4">
				<div className="container">
				<p className="copyright-text text-center pb-md-0 pb-4 fs-13">{cmsData.footer_copyright}</p>
				</div>
			</div>
		</footer>
		</>
	);
}
export default Footer;