import React from "react";
import { useSelector } from 'react-redux';
import { getImageUrl } from '../utils';
import separator from '../images/sep.png' 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Form from "./Forms/Form";
import { ToastContainer } from "react-toastify";


const Home=()=>{
	const settings = {
		
		centerMode: true,
		infinite: true,
		centerPadding: "60px",
		slidesToShow: 3,
		speed: 500,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
	  };
	const cmsData = useSelector((state) => state.cms.cmsData);
	const services = useSelector((state)=>state.service.services);
	const testimonials = useSelector((state)=> state.testimonial.testimonials);
	const projects = useSelector((state)=>state.project.projects);
	const achievements = useSelector((state) => state.achievement.achievementData || []); // Ensure it defaults to an empty array
	console.log(achievements);

	return(
		<>
		<ToastContainer position="top-right" theme="dark" autoClose={3000} hideProgressBar />

		<div data-bs-spy="scroll" data-bs-target="#mainNavBar" data-bs-root-margin="0px 0px -0%" data-bs-smooth-scroll="true"  tabindex="0">

			<section id="home" className="home-banner">
			<div id="homeMainCarousel" className="carousel slide" data-bs-ride="carousel">
				<div className="carousel-inner">
				<div className="carousel-item active py-5 slideone" >
					<em className="bg-black opacity-75 position-absolute top-0 end-0 start-0 bottom-0"></em>
					<div className="container position-relative h-100 py-5">
					<div className="row align-items-center justify-content-center text-center h-inherit">
						<div className="col-lg-8 col-md-10 py-5">
						<h1 className="display-3 text-white fw-bolder wow animated fadeInDown ff-josefine">{cmsData.banner_main_heading}</h1>
						<p className="d-block fs-5 text-white lh-180 py-4 my-3 wow animated fadeInDown ff-josefine">{cmsData.banner_main_sub_heading}</p>
						<a href="#getInTouch" className="fw-bolder btn btn-primary-dt btn-lg text-uppercase px-4 py-3 mt-4 wow animated fadeInUp">Contact Us</a>
						</div>
					</div>
					</div>
				</div>
				<div className="carousel-item py-5 slidetwo">
					<em className="bg-black opacity-75 position-absolute top-0 end-0 start-0 bottom-0"></em>
					<div className="container position-relative h-100 py-5">
					<div className="row align-items-center justify-content-center text-center h-inherit">
						<div className="col-lg-8 col-md-10 py-5">
						<h1 className="display-3 text-white fw-bolder wow animated fadeInDown ff-josefine">{cmsData.banner_main_heading}</h1>
						<p className="d-block fs-5 text-white lh-180 py-4 my-3 wow animated fadeInDown ff-josefine">{cmsData.banner_main_sub_heading}</p>
						<a href="#getInTouch" className="fw-bolder btn btn-primary-dt btn-lg text-uppercase px-4 py-3 mt-4 wow animated fadeInUp">Contact Us</a>
						</div>
					</div>
					</div>
				</div>
				<div className="carousel-item py-5 slidethree" >
					<em className="bg-black opacity-75 position-absolute top-0 end-0 start-0 bottom-0"></em>
					<div className="container position-relative h-100 py-5">
					<div className="row align-items-center justify-content-center text-center h-inherit">
						<div className="col-lg-8 col-md-10 py-5">
						<h1 className="display-3 text-white fw-bolder wow animated fadeInDown ff-josefine">{cmsData.banner_main_heading}</h1>
						<p className="d-block fs-5 text-white lh-180 py-4 my-3 wow animated fadeInDown ff-josefine">{cmsData.banner_main_sub_heading}</p>
						<a href="#getInTouch" className="fw-bolder btn btn-primary-dt btn-lg text-uppercase px-4 py-3 mt-4 wow animated fadeInUp">Contact Us</a>
						</div>
					</div>
					</div>
				</div>
				</div>
				<button className="carousel-control-prev" type="button" data-bs-target="#homeMainCarousel" data-bs-slide="prev"><i className="fa fa-angle-left fa-2x"></i></button>
				<button className="carousel-control-next" type="button" data-bs-target="#homeMainCarousel" data-bs-slide="next"><i className="fa fa-angle-right fa-2x"></i></button>
			</div>
			</section>

			<section id="aboutUs" className="py-5 overflow-hidden about-us">
			<div className="container py-md-5 py-4">
				<div className="row align-items-center">
				<div className="col-lg-6 py-4 order-lg-1 order-2">
					<div className="overflow-hidden wow animated slideInLeft rounded-2 shadow border"><img src={getImageUrl(cmsData.about_us_image)} alt="About Us" className="img-fluid hvr-bounce-in mb-n1" /></div>
				</div>
				<div className="col-lg-6 my-4 ps-lg-5 order-lg-2 order-1">
					<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated slideInRight text-lg-start text-center">{cmsData.about_us_breadcrumb}</span>
					<h2 className="h1 ff-josefine fw-bold wow animated slideInRight text-lg-start text-center">{cmsData.about_us_title}</h2>
					<div className=" text-lg-start text-center"><img src={separator} alt="Seperator" className="wow animated slideInRight dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated slideInRight light-sep" /></div>
					<p className="aboutus_decription d-block py-4 my-3 lh-180 wow animated slideInRight "  dangerouslySetInnerHTML={{
                            __html: cmsData.about_us_description,
                          }}></p>
					{/* <ul className="d-block">
					<li className="d-flex gap-2 mb-4 wow animated slideInRight lh-150"><i className="fa fa-angle-right text-primary-dt mt-1"></i> <p><b className="text-primary-dt">Mission:</b> We deliver Uniqueness and Quality.</p></li>
					<li className="d-flex gap-2 mb-4 wow animated slideInRight lh-150"><i className="fa fa-angle-right text-primary-dt mt-1"></i> <p><b className="text-primary-dt">Skills:</b> Delivering fast and excellent results.</p></li>
					<li className="d-flex gap-2 mb-4 wow animated slideInRight lh-150"><i className="fa fa-angle-right text-primary-dt mt-1"></i> <p><b className="text-primary-dt">Clients:</b> Satisfied clients thanks to our experience.</p></li>
					</ul> */}
				</div>
				</div>
			</div>
			</section>

			<section id="ourServices" className="py-5 overflow-hidden services bg-light">
			<div className="container py-md-5 py-4">
				<div className="row justify-content-center">
				<div className="col-lg-6 col-md-8 text-center">
					<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated fadeInDown">What We Do?</span>
					<h2 className="h1 ff-josefine fw-bold wow animated fadeInDown">{cmsData.services_title}</h2>
					<img src={separator} alt="Seperator" className="wow animated fadeInDown dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated fadeInDown light-sep" />
					<p className="d-block wow animated fadeInDown lh-170 pt-4 mt-2" dangerouslySetInnerHTML={{
						__html: cmsData.services_description,
					  }}></p>
				</div>
				</div>
				<div className="row align-items-stretch mt-5">
				{services && services.length > 0 ? (
				services.map((service) => (
					<div key={service.id} className="col-lg-3 col-md-6 mt-4 wow animated fadeInUp">
					<div className="service-box h-100 w-100 transition px-4 py-5 rounded-2 text-center bg-white shadow-sm hvr-underline-from-center">
						<i className={`${service.icon} fa-3x transition`}></i> 
						<h2 className="h5 fw-bolder text-uppercase ff-josefine mt-4 pt-1 mb-3 transition">{service.title}</h2>
						<p className="lh-170">Our experienced developers combine their technical expertise...</p>
						<Link to={`/service/${service.service_slug}`} className="btn btn-primary-dt mt-4 fs-13 px-4">
							Read More
						</Link>
					</div>
					</div>
				))
				) : (
				<p>No services available</p>
				)}
				
				</div>
			</div>
			</section>

			<section id="clients" className="clients py-5 overflow-hidden">
			<div className="py-md-5 py-4">
				<div className="row justify-content-center">
				<div className="col-lg-6 col-md-8 text-center">
					<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated fadeInDown">Client's Feedback</span>
					<h2 className="h1 ff-josefine fw-bold wow animated fadeInDown">{cmsData.testimonials_title}</h2>
					<img src={separator} alt="Seperator" className="wow animated fadeInDown dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated fadeInDown light-sep" />
				</div>
				</div>
				<div className="row justify-content-center mt-5 pt-4 pb-5 mb-4">
				<div className="col-lg-6 col-md-8 wow animated fadeInUp">
					<div id="clientsReviews" className="carousel slide">
					<div className="carousel-inner">
						{testimonials && testimonials.length > 0? (
							testimonials.map((testimonial, index)=>
							<div className={`carousel-item ${index === 0 ? 'active' : ''}`} >
							<div className="review-text text-center">
								<p className="d-block lh-150 fst-italic fs-4 fw-light ff-josefine">"{testimonial.response}"</p>
								<img src={getImageUrl(testimonial.image)} alt="Client" className="rounded-circle border mt-5" width="150" height="150" />
								<span className="d-block pb-2 pt-4"><i className="fa fa-star text-warning"></i> <i className="fa fa-star text-warning"></i> <i className="fa fa-star text-warning"></i> <i className="fa fa-star text-warning"></i> <i className="fa fa-star text-warning"></i></span>
								<strong className="d-block pt-2 pb-1 fs-5">{testimonial.name} - <span className="fs-6 fw-normal pt-2">{testimonial.company}</span></strong>
							</div>
							</div>)
						):(<></>)}
						
					</div>
					<button className="carousel-control-prev btn btn-primary-dt w-auto h-auto p-3" type="button" data-bs-target="#clientsReviews" data-bs-slide="prev"><i className="fa fa-angle-left"></i></button>
					<button className="carousel-control-next btn btn-primary-dt w-auto h-auto p-3" type="button" data-bs-target="#clientsReviews" data-bs-slide="next"><i className="fa fa-angle-right"></i></button>
					</div>
				</div>
				</div>
			</div>
			</section>

			<section className="counter py-5 bg-black">
			<div className="container py-2">
				<div className="row">
				<div className="col-md-3 text-center my-3 text-white wow animated fadeInDown">
					<i className="fa fa-folder-plus fa-4x text-primary-dt"></i>
					<h2 className="display-5 pt-4 pb-2 fw-bold ff-josefine">{cmsData.project_count}</h2>
					<p className="d-block lh-150 fs-5">Completed Projects</p>
				</div>
				<div className="col-md-3 text-center my-3 text-white wow animated fadeInDown">
					<i className="fa fa-globe fa-4x text-primary-dt"></i>
					<h2 className="display-5 pt-4 pb-2 fw-bold ff-josefine">{cmsData.available_country}</h2>
					<p className="d-block lh-150 fs-5">Available Country</p>
				</div>
				<div className="col-md-3 text-center my-3 text-white wow animated fadeInDown">
					<i className="fa fa-user-tie fa-4x text-primary-dt"></i>
					<h2 className="display-5 pt-4 pb-2 fw-bold ff-josefine">{cmsData.worker}</h2>
					<p className="d-block lh-150 fs-5">Our Employees</p>
				</div>
				<div className="col-md-3 text-center my-3 text-white wow animated fadeInDown">
					<i className="fa fa-users fa-4x text-primary-dt"></i>
					<h2 className="display-5 pt-4 pb-2 fw-bold ff-josefine">{cmsData.happy_clients}</h2>
					<p className="d-block lh-150 fs-5">Window User</p>
				</div>
				</div>
			</div>
			</section>
			{projects ?(
				<section id="projects" className="projects py-5 bg-light">
					<div className="container py-md-5 py-4">
						<div className="row justify-content-center">
						<div className="col-lg-6 col-md-8 text-center">
							<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated fadeInDown">Work We Have Done</span>
							<h2 className="h1 ff-josefine fw-bold wow animated fadeInDown">Our Projects</h2>
							<img src={separator} alt="Seperator" className="wow animated fadeInDown dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated fadeInDown light-sep" />
						</div>
						</div>
						<div className="row">
						<div className="col-12 text-center">
							<ul className="nav nav-pills justify-content-center border-0 mt-5 wow animated fadeInDown" id="portfolio" role="tablist">
							<li className="nav-item" role="presentation">
								<button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all-tab-pane" type="button" role="tab" aria-controls="all-tab-pane" aria-selected="true">All</button>
							</li>
							{projects.categoriesProjects?.map((category)=>
								<li className="nav-item" role="presentation">
									<button className="nav-link" id={`${category.id}-tab`} data-bs-toggle="tab" data-bs-target={`#${category.id}-tab-pane`} type="button" role="tab" aria-controls={`${category.id}-tab-pane`} aria-selected="false">{category.title}</button>
								</li>
							)}
							</ul>
						</div>
						<div className="col-12 wow animated fadeInDown">
							<div className="tab-content" id="portfolioContent">
							<div className="tab-pane fade show active" id="all-tab-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="0">
								<div className="container">
								<div className="row mt-5 justify-content-center">
									{projects.projects?.map((project)=>
									<div className="col-lg-4 col-md-6 mt-4">
									<div className="project-box position-relative rounded-1 overflow-hidden transition border shadow-sm">
										<em className="position-absolute top-0 end-0 start-0 bottom-0 bg-black transition p-overlay"></em>
										<div className="d-flex justify-content-center align-items-center p-4 h-100 position-absolute start-0 end-0 top-0 bottom-0 p-details transition">
										<a href={project.url} target="_blank" className="btn fs-4 btn-primary-dt"><i className="fa fa-link"></i></a>
										</div>
										<img src={getImageUrl(project.image)} className="img-fluid h-100 w-100 transition object-fit-cover" />
									</div>
									</div>)}
								</div>
								</div>
							</div>
							{
								projects.categoriesProjects?.map((category)=>
									<div className="tab-pane fade" id={`${category.id}-tab-pane`} role="tabpanel" aria-labelledby={`${category.id}-tab`} tabindex="0">
										<div className="container">
										<div className="row mt-5 justify-content-center">
											{category.projects.length >0 ?(
												category.projects.map((project)=>
													<div className="col-lg-4 col-md-6 mt-4">
												<div className="project-box position-relative rounded-1 overflow-hidden transition border shadow-sm">
													<em className="position-absolute top-0 end-0 start-0 bottom-0 bg-black transition p-overlay"></em>
													<div className="d-flex justify-content-center align-items-center p-4 h-100 position-absolute start-0 end-0 top-0 bottom-0 p-details transition">
													<a href={project.url} target="_blank" className="btn fs-4 btn-primary-dt"><i className="fa fa-link"></i></a>
													</div>
													<img src={getImageUrl(project.image)} className="img-fluid h-100 w-100 transition object-fit-cover" />
												</div>
												</div>)
											):(<h1>No projects</h1>)}
										</div>
										</div>
									</div>)
							}
							</div>
						</div>
						</div>
					</div>
				</section>):(<></>)}
			

			<section id="achievements" className=" py-5">
			<div className="container py-md-5 py-4">
				<div className="row justify-content-center">
				<div className="col-lg-6 col-md-8 text-center">
					<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated fadeInDown">What We Got</span>
					<h2 className="h1 ff-josefine fw-bold wow animated fadeInDown">Our Latest Achievements</h2>
					<img src={separator} alt="Seperator" className="wow animated fadeInDown dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated fadeInDown light-sep" />
				</div>
				</div>
			</div>
		
			<div className="slider-container">
				<Slider {...settings}>
					{achievements.length > 0 ? (
						achievements.map((achievement) => (
						<div key={achievement.id}> {/* Add a unique key */}
							<img src={getImageUrl(achievement.image)} alt="Achievement" className="img-fluid w-100 object-fit-cover" />
						</div>
						))
					) : (
						<p>No achievements available.</p> // Optional fallback in case there are no achievements
					)}
				</Slider>
			</div>
			</section>

			<section id="getInTouch" className="get-in-touch py-5 bg-light overflow-hidden">
			<div className="container py-md-5 py-4">
				<div className="row justify-content-center">
				<div className="col-lg-6 col-md-8 text-center">
					<span className="fs-6 text-primary-dt ff-josefine d-block pb-2 wow animated fadeInDown">Have Some Queries?</span>
					<h2 className="h1 ff-josefine fw-bold wow animated fadeInDown">{cmsData.contact_us_title}</h2>
					<img src={separator} alt="Seperator" className="wow animated fadeInDown dark-sep" /><img src="images/sep-white.png" alt="Seperator" className="wow animated fadeInDown light-sep" />
				</div>
				</div>
				<div className="row mt-5">
				<div className="col-lg-7 col-md-6 mt-4 px-md-4 px-lg-5 wow animated slideInLeft">
					<Form />
				</div>
				<div className="col-lg-5 col-md-6 mt-4 px-md-4 px-lg-5 wow animated slideInRight">
					<ul className="d-block">
					<li className="d-flex align-items-center gap-3 mb-5"><i className="fa fa-map-marker-alt fa-2x text-primary-dt"></i> <a href="javascript:;" className="text-dark fs-5 lh-150">{cmsData.iframe_link}</a></li>
					<li className="d-flex align-items-center gap-3 mb-5"><i className="fa fa-envelope fa-2x text-primary-dt"></i> <a href={`mailto:${cmsData.contact_us_email}`} className="text-dark fs-5 lh-150">{cmsData.contact_us_email}</a></li>
					<li className="d-flex align-items-center gap-3 mb-5"><i className="fa fa-phone-alt fa-2x text-primary-dt"></i> <a href={`tel:${cmsData.contact_us_number}`} className="text-dark fs-5 lh-150">{cmsData.contact_us_number}</a></li>
					</ul>
				</div>
				</div>
			</div>
			</section>

		</div>
		</>
	);
}
export default Home;