import { cmsSettings } from '../../services/api';


export const FETCH_CMS_DATA_SUCCESS = 'FETCH_CMS_DATA_SUCCESS';
export const FETCH_CMS_DATA_FAILURE = 'FETCH_CMS_DATA_FAILURE';
export const FETCH_CMS_DATA_REQUEST = 'FETCH_CMS_DATA_REQUEST';


export const fetchCmsData = () => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_CMS_DATA_REQUEST
        })
        
        const response = await cmsSettings(); 
        dispatch({
            type: FETCH_CMS_DATA_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_CMS_DATA_FAILURE,
            payload: error.message,
        });
    }
};