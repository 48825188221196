import { FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECTS_REQUEST } from "../actions/projectsAction";

const initialState = {
    projects: {},
	loading:true,
    error: null,
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
		case FETCH_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload,
                loading:false,
                error: null,
            };
        case FETCH_PROJECTS_FAILURE:
            return {
                ...state,
                projects: {},
                loading:false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default projectReducer;