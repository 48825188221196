import { testimonials } from "../../services/api";

export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS';
export const FETCH_TESTIMONIALS_FAILURE = 'FETCH_TESTIMONIALS_FAILURE';
export const FETCH_TESTIMONIALS_REQUEST = 'FETCH_TESTIMONIALS_REQUEST';


export const fetchtTestimonialData = () => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_TESTIMONIALS_REQUEST
        });
        const response = await testimonials(); 
        dispatch({
            type: FETCH_TESTIMONIALS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_TESTIMONIALS_FAILURE,
            payload: error.message,
        });
    }
};