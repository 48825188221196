import React, { useEffect, useState } from 'react';

function ThemeToggle() {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('themeMode') === 'dark'
  );

  useEffect(() => {
    const main = document.documentElement;

    if (isDarkMode) {
      // Apply dark theme
      const darkLink = document.createElement('link');
      darkLink.rel = 'stylesheet';
      darkLink.href = 'assets/css/dark.css';
      darkLink.id = 'dark';
      document.head.appendChild(darkLink);
      document.body.classList.add('dark-mode');
      main.setAttribute('data-bs-theme', 'dark');
    } else {
      // Remove dark theme
      const darkStyle = document.getElementById('dark');
      if (darkStyle) darkStyle.remove();
      document.body.classList.remove('dark-mode');
      main.setAttribute('data-bs-theme', '');
    }
  }, [isDarkMode]);

  const handleThemeChange = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      if (newMode) {
        localStorage.setItem('themeMode', 'dark');
      } else {
        localStorage.removeItem('themeMode');
      }
      return newMode;
    });
  };

  return (
    <li className="nav-item">
      <div className="one-quarter ms-lg-3" id="switch">
        <input
          type="checkbox"
          className="checkbox"
          id="themeMode"
          checked={isDarkMode}
          onChange={handleThemeChange}
        />
        <label className="label" htmlFor="themeMode">
          <i className="fas fa-moon text-white"></i>
          <i className="fas fa-sun text-white"></i>
          <div className="ball"></div>
        </label>
      </div>
    </li>
  );
}

export default ThemeToggle;
