import { projects } from "../../services/api";

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const fetchProjects =()=>async(dispatch)=>{
	try {
		dispatch({
			type:FETCH_PROJECTS_REQUEST
		})
        const response = await projects(); 
        dispatch({
            type: FETCH_PROJECTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_PROJECTS_FAILURE,
            payload: error.message,
        });
    }
}