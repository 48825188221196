import { achievements } from "../../services/api";

export const FETCH_ACHIEVEMENTS_SUCCESS = 'FETCH_ACHIEVEMENTS_SUCCESS';
export const FETCH_ACHIEVEMENTS_FAILURE = 'FETCH_ACHIEVEMENTS_FAILURE';
export const FETCH_ACHIEVEMENTS_REQUEST = 'FETCH_ACHIEVEMENTS_REQUEST';


export const fetchAchievementData = () => async (dispatch) => {
    try {
		dispatch({
			type: FETCH_ACHIEVEMENTS_REQUEST,
		})
        const response = await achievements(); 
        dispatch({
            type: FETCH_ACHIEVEMENTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_ACHIEVEMENTS_FAILURE,
            payload: error.message,
        });
    }
};