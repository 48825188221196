
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCmsData } from './redux/actions/cmsActions';
import { fetchServices } from "./redux/actions/servicesAction";
import { fetchtTestimonialData } from "./redux/actions/testimonials";
import { fetchProjects } from "./redux/actions/projectsAction";
import { fetchAchievementData } from "./redux/actions/achievementsActions";
import { fetchTeams } from "./redux/actions/teamAction";
import { useEffect } from "react";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Team from "./components/Team";
import Home from "./components/Home";
import Service from "./components/Service";
import { setLoading } from './redux/reducers/loadingReducer';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        await Promise.all([
          dispatch(fetchCmsData()),
          dispatch(fetchServices()),
          dispatch(fetchtTestimonialData()),
          dispatch(fetchProjects()),
          dispatch(fetchAchievementData()),
          dispatch(fetchTeams()),
        ]);
      } catch (error) {
        console.error("Data fetching error:", error);
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [dispatch]);

  const { isLoading } = useSelector(state => state.loading);

  return (
    <>
      {isLoading ? (
        <div id="loader" className="lds-dual-ring"></div>
      ) : (
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Team />} />
            <Route path="/service/:service_slug" element={<Service />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </>
  );
}


export default App;
