import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
});

export const cmsSettings = () => API.get("/settings");
export const services =()=> API.get("/services");
export const testimonials =()=> API.get("/testimonials");
export const projects = ()=> API.get("/projects");
export const achievements = ()=> API.get("/achievements");
export const team = ()=> API.get("/team");
