import { combineReducers } from 'redux';
import cmsReducer from './cmsReducer';
import serviceReducer from './servicesReducer';
import testimonialReducer from './testimonialReducer';
import projectReducer from './projectReducer';
import achievementReducer from './achievementReducer';
import teamReducer from './teamReducer';
import loadingReducer from './loadingReducer';



const rootReducer = combineReducers({
    cms: cmsReducer,
    service: serviceReducer,
    testimonial: testimonialReducer,
    project: projectReducer,
    achievement: achievementReducer,
    team: teamReducer,
    loading: loadingReducer, // Add it here
});

export default rootReducer;