import { FETCH_TESTIMONIALS_SUCCESS, FETCH_TESTIMONIALS_FAILURE, FETCH_TESTIMONIALS_REQUEST } from "../actions/testimonials";

const initialState ={
	testimonials :{},
	loading: false,
	error: null,
}

const testimonialReducer =(state= initialState, action)=>{
	switch(action.type){
		case FETCH_TESTIMONIALS_REQUEST:
            return {
                ...state,
                loading: true,
            };
		case FETCH_TESTIMONIALS_SUCCESS:
			return {
				...state,
                testimonials: action.payload,
                error: null,
			};
		case FETCH_TESTIMONIALS_FAILURE:
			return {
				...state,
				testimonials: {},
				error: action.payload,
			};
		default:
			return state;
	}
}

export default testimonialReducer;
