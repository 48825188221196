import { FETCH_TEAM_SUCCESS, FETCH_TEAM_FAILURE} from '../actions/teamAction';


const initialState = {
    team: {},
    loading: true,
    error: null,
};

const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEAM_SUCCESS:
            return {
                ...state,
                team: action.payload,
                loading:false,
                error: null,
            };
        case FETCH_TEAM_FAILURE:
            return {
                ...state,
                team: {},
                error: action.payload,
            };
        default:
            return state;
    }
};

export default teamReducer;
