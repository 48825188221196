import { FETCH_CMS_DATA_SUCCESS, FETCH_CMS_DATA_FAILURE, FETCH_CMS_DATA_REQUEST } from '../actions/cmsActions';

const initialState = {
    cmsData: {},
    loading: true,
    error: null,
};

const cmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CMS_DATA_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_CMS_DATA_SUCCESS:
            return {
                ...state,
                cmsData: action.payload,
                loading:false,
                error: null,
            };
        case FETCH_CMS_DATA_FAILURE:
            return {
                ...state,
                cmsData: {},
                loading:false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default cmsReducer;

