import { FETCH_SERVICES_SUCCESS, FETCH_SERVICES_FAILURE ,FETCH_SERVICES_REQUEST} from "../actions/servicesAction";

const initialState = {
    services: {},
    loading: false,
    error: null,
};

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload,
                error: null,
            };
        case FETCH_SERVICES_FAILURE:
            return {
                ...state,
                services: {},
                error: action.payload,
            };
        default:
            return state;
    }
};

export default serviceReducer;