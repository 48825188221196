import { services } from "../../services/api";

export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';


export const fetchServices = () => async (dispatch) => {
    try {
        dispatch({
			type:FETCH_SERVICES_REQUEST
		})
        const response = await services(); 
        dispatch({
            type: FETCH_SERVICES_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_SERVICES_FAILURE,
            payload: error.message,
        });
    }
};